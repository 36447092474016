import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormTextarea,CInputGroup,CInputGroupText,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyDialogview,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import Resizer from "react-image-file-resizer";
import ImageGallery from 'react-image-gallery';
import { ContHeaderProfile } from '../../containers/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Pasienrmdetil = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props;
	const { id_rm } 			= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_laporan || 100);
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;

	const uRekammedisID					= parseInt(id_rm||"0");
	const refFileImg 					= React.useRef();
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const uLayananArr				= JSON.parse(localStorage.getItem("layananlist")||"[]");
	const [uDatamainObj,setDatamainObj]	= React.useState({})
	const [uDataupdateObj,setDataupdateObj]= React.useState({})
	const [uISDataloading,setDataloading]= React.useState(false)

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	const [uDlgviewheader,setDlgviewheader]	= React.useState("")
	const [uIsDlgviewshow,setDlgviewshow]	= React.useState(false)
	//--END DLG_VIEW--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikReset = async() => {
		if(uHtml500msg) { apiLoaddata(); return }

		if(await Konfirm(uBahasaObj.confirm_yakin)) apiLoaddata();
	}
	const hdlKDownfield = (_EV,_NAMAFIELD) => {
		if(_EV.keyCode===13) {
			if(_EV.target.nodeName === "TEXTAREA") {
				if(_EV.shiftKey) return;
				if(isMobile) return;
			}

			_EV.preventDefault();
			hdlKlikProsesfield(_NAMAFIELD);
			return;
		}
		if(_EV.keyCode===27) {
			_EV.preventDefault();
			setDataupdateObj({});setEfekview(!uEfekview);
			return;
		}
	}
	const hdlKlikEditfield = (_NAMAFIELD) => {
		_NAMAFIELD = (_NAMAFIELD||"").trim();
		if(_NAMAFIELD.toUpperCase() === ("keluhan").toUpperCase())
			uDataupdateObj.value 	= uDatamainObj.keluhan||"";
		else if(_NAMAFIELD.toUpperCase() === ("anamnesa").toUpperCase())
			uDataupdateObj.value 	= uDatamainObj.anamnesa||"";
		else if(_NAMAFIELD.toUpperCase() === ("diagnosa").toUpperCase())
			uDataupdateObj.value 	= uDatamainObj.diagnosa||"";
		else if(_NAMAFIELD.toUpperCase() === ("cek_suhu").toUpperCase()){
			uDataupdateObj.value 	= uDatamainObj.cek_suhu||"0";
			if(isMobile) 
				uDataupdateObj.value = UFunc.formatAngka(uDatamainObj.cek_suhu)
		}
		else if(_NAMAFIELD.toUpperCase() === ("berat_badan").toUpperCase()) {
			uDataupdateObj.value 	= uDatamainObj.berat_badan||"0";
			if(isMobile) 
				uDataupdateObj.value = UFunc.formatAngka(uDatamainObj.berat_badan)
		}
		else if(_NAMAFIELD.toUpperCase() === ("note_template").toUpperCase())
			uDataupdateObj.value 	= uDatamainObj.note_template||"";
		uDataupdateObj.fieldedit = _NAMAFIELD; setEfekview(!uEfekview);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inpeditfield");
			vElfocus && vElfocus.focus();
		},350)
	}
	const hdlKlikProsesfield = (_NAMAFIELD) => { apiProsesedit(_NAMAFIELD) }
	const hdlKlikFotoview = () => {
		const vFotoArr 	= uDatamainObj.foto_arr || [];
		if(vFotoArr.length <= 0) return;

		setDlgviewshow(true);
	}
	const hdlKlikFototambah = () => { refFileImg.current.click(); }
	const hdlChangeFotofake = (_EV) => {
		const vFileUploadedArr 	= Array.from(_EV.target.files);

		//console.log("(Pasienrmdetil - hdlChangeFoto) vFileUploadedArr => "+JSON.stringify(vFileUploadedArr));

		if(vFileUploadedArr.length <= 0) return;
		if(vFileUploadedArr.length > 10) {
			_EV.target.value = '';
			showToast("<b>Limited Upload Foto</b>.."+
				"<br/>Untuk Menjaga Realibilitas Server, Sekali Pengiriman Mohon Untuk Tidak Lebih Dari 10 Foto !"+
				"");
			return;
		}

		//--CEK_VALIDASI_FILE--/
		let vFilebaseArr	= [];
		const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
		vFileUploadedArr.forEach(vItems => {
			const vFileuploaded = vItems;
			if (!allowedTypes.includes(vFileuploaded?.type)) {
				_EV.target.value = '';
				showToast("<b>Only JPEG, PNG, and GIF images are allowed</b>.."+
					"<br/>Ada TIPE File yang Tidak Berformat IMAGE."+
					"<br/><br/>Mohon Diperbaiki !"+
					"");
				return;
			}
		});
		//--END CEK_VALIDASI_FILE--/

		const resizeAndConvertToBase64 = (_FILE,_TYPE) => {
			return new Promise((resolve, reject) => {
				Resizer.imageFileResizer(
					_FILE,
					1300, // New width
					800, // New height
					_TYPE||"JPEG", // Format
					50, // Quality
					0, // Rotation
					(uri) => {
						resolve(uri); // This will be a base64 string
					},
					'base64' // Output type
				);
			});
		};

		const base64Promises = vFileUploadedArr.map((file) => {
			//--JIKA_FILESIZE_LEBIH_DARI_1MB--/
			const vFilesize	= (parseFloat(file.size) || 0) / 1024 / 1024;
			if(vFilesize > 1) {
				const vFiletype	= (file.type||"jpeg").
					replace(/^image\//, "").toUpperCase();

				return resizeAndConvertToBase64(file,vFiletype);
			}
			//--END JIKA_FILESIZE_LEBIH_DARI_1MB--/

			return UFunc.getBase64(file)
		});

		Promise.all(base64Promises).
			then((base64Files) => { 
				//--VALIDASI_TOTAL_FILE_IN_BYTES--//-->LIMIT_5MB--/
				const vTotalresizedSize = base64Files.reduce((acc, img) => acc + (img.length * 3) / 4, 0);
				if((vTotalresizedSize / 1024 / 1024) > 5) {
					_EV.target.value = '';

					showToast("<b>Total Pengiriman Foto Maksimal 5MB</b>.."+
						"<br/>Kemungkinan Ada File yang Berukuran Cukup Besar."+
						"<br/><br/>Mohon Upload Foto melalui beberapa sesi Pengiriman !"+
						"");
					return;
				}
				//--END VALIDASI_TOTAL_FILE--/

				//console.log("(Pasienrmdetil - hdlChangeFoto) base64Files [1] => "+JSON.stringify(base64Files));
				//--PROSES_API_ARRAY--/
				_EV.target.value = '';
				apiProsesfoto(base64Files);//--ARRAY_BASE64--/
				//--END PROSES_API_ARRAY--/
			}).
			catch((error) => {
				console.error('Error converting files to Base64:', error);
			});
	}
	const hdlKlikfotohapus = async(_IDX) => {
		//console.log("(Pasienrmdetil - hdlKlikfotohapus) _IDX1 => "+_IDX)
		_IDX = parseInt(_IDX);
		//console.log("(Pasienrmdetil - hdlKlikfotohapus) _IDX2 => "+_IDX)
		if(_IDX < 0) return;

		const vActionhapus = () => {
			//---API--/
			apiProsesfotohapus(_IDX)
			//---END API--/
		}

		if(await Konfirm(
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>FOTO REKAMMEDIS..</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR>"+
			"<BR>"+
			"<B>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</B>")) {
			vActionhapus();
		}
	}

	const hdlToggleDlgview = () => { setDlgviewshow(false); }
	const hdlKlikSimpanmain = () => {}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentAnamnesa = () => {
		if(uHtml500msg) return ""

		const vFieldteks = (_NAMAFIELD) => {
			return (
			<CInputGroup size="sm">
				<CFormInput size="sm"
					value={uDataupdateObj.value||""}
					onChange={(e)=>{uDataupdateObj.value=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>hdlKDownfield(e,_NAMAFIELD)}
					readOnly={uDataupdateObj.isProcessing||false}
					id="inpeditfield"/>
				{(uDataupdateObj.isProcessing||false)===false ? (
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikProsesfield(_NAMAFIELD)}
					id="btneditfield">
				<CIcon icon="cilCheckAlt" className="text-white"/>
				</CButton>
				) : (
				<CInputGroupText className="bg-transparent border-0">
				<CSpinner color="danger" size="sm"/>
				</CInputGroupText>
				)}
			</CInputGroup>
			)//>
		}
		const vFieldnumber = (_NAMAFIELD) => {
			const vSatuan = _NAMAFIELD==="cek_suhu" 
				? (<>&deg; C</>) 
				: (<>KG</>)//>

			return (
			<CInputGroup size="sm">
				<CFormInput size="sm" type={isMobile ? "text" : "number"}
					className="text-end"
					value={uDataupdateObj.value}
					onChange={(e)=>{
						if(!isMobile) {
							uDataupdateObj.value=e.target.value;
							setEfekview(!uEfekview);
							return;
						}
						const vCorrectedvalue 	= (e.target.value||"").replace(/[^0-9,]/g, '');
						uDataupdateObj.value 	= vCorrectedvalue;
						setEfekview(!uEfekview);
					}}
					onKeyDown={(e)=>hdlKDownfield(e,_NAMAFIELD)}
					onFocus={(e)=>e.target.select()}
					readOnly={uDataupdateObj.isProcessing||false}
					min={0} max={100} 
					id="inpeditfield"/>
				<CInputGroupText className="fw-bolder">{vSatuan}</CInputGroupText>
				<CInputGroupText className="bg-transparent border-0 p-0 mx-1"/>

				{(uDataupdateObj.isProcessing||false)===false ? (
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikProsesfield(_NAMAFIELD)}
					id="btneditfield">
				<CIcon icon="cilCheckAlt" className="text-white"/>
				</CButton>
				) : (
				<CInputGroupText className="bg-transparent border-0">
				<CSpinner color="danger" size="sm"/>
				</CInputGroupText>
				)}
			</CInputGroup>
			)//>
		}

		return (
		<CCard className="mb-3 classcardbg">
		<CCardHeader><b>{uBahasaObj.caption_dataanamnesa}</b></CCardHeader>
		<CCardBody>
		{uISDataloading ? (
			<div className="text-center py-3">
			<CSpinner color="primary" className="mx-auto"/>
			</div>
		) : (
		<>
		<CRow className="mx-0 d-none">
			<CCol>{uBahasaObj.word_keluhan}</CCol>
			{(uDataupdateObj.fieldedit||"") !== "keluhan" ? (
			<>
			<CCol xs="7" className="text-end fw-bolder text-capitalize">
				{uDatamainObj.keluhan||"-"}
			</CCol>
			<CCol xs="1" className="text-center">
			<CTooltip content={uBahasaObj.word_edit+" "+uBahasaObj.word_keluhan}>
			<CLink onClick={()=>hdlKlikEditfield("keluhan")} 
				className="classcursorpointer classikontabel classikonedit"/>
			</CTooltip>
			</CCol>
			</>
			) : (
			<CCol xs="8" className="text-end">{vFieldteks("keluhan")}</CCol>
			)}
		</CRow>

		<CRow className="mx-0">
			<CCol>{uBahasaObj.word_anamnesa}</CCol>
			{(uDataupdateObj.fieldedit||"") !== "anamnesa" ? (
			<>
			<CCol xs="7" className="text-end fw-bolder text-capitalize">
				{uDatamainObj.anamnesa||"-"}
			</CCol>
			<CCol xs="1" className="text-center">
			<CTooltip content={uBahasaObj.word_edit+" "+uBahasaObj.word_anamnesa}>
			<CLink onClick={()=>hdlKlikEditfield("anamnesa")} 
				className="classcursorpointer classikontabel classikonedit"/>
			</CTooltip>
			</CCol>
			</>
			) : (
			<CCol xs="8" className="pe-0 text-end">{vFieldteks("anamnesa")}</CCol>
			)}
		</CRow>
		<CRow className="mx-0">
			<CCol>{uBahasaObj.word_diagnosa}</CCol>
			{(uDataupdateObj.fieldedit||"") !== "diagnosa" ? (
			<>
			<CCol xs="7" className="text-end fw-bolder text-capitalize">
				{uDatamainObj.diagnosa||"-"}
			</CCol>
			<CCol xs="1" className="text-center">
			<CTooltip content={uBahasaObj.word_edit+" "+uBahasaObj.word_diagnosa}>
			<CLink onClick={()=>hdlKlikEditfield("diagnosa")} 
				className="classcursorpointer classikontabel classikonedit"/>
			</CTooltip>
			</CCol>
			</>
			) : (
			<CCol xs="8" className="pe-0 text-end">{vFieldteks("diagnosa")}</CCol>
			)}
		</CRow>

		<CRow className="mx-0">
			<CCol>{uBahasaObj.caption_suhubadan}</CCol>
			{(uDataupdateObj.fieldedit||"") !== "cek_suhu" ? (
			<>
			<CCol xs="7" className="text-end fw-bolder">
				{UFunc.formatAngka(uDatamainObj.cek_suhu)}&deg; C
			</CCol>
			<CCol xs="1" className="text-center">
			<CTooltip content={uBahasaObj.word_edit+" "+uBahasaObj.caption_suhubadan}>
			<CLink onClick={()=>hdlKlikEditfield("cek_suhu")} 
				className="classcursorpointer classikontabel classikonedit"/>
			</CTooltip>
			</CCol>
			</>
			) : (
			<CCol xs="8" md="5" className="pe-0 text-end ps-lg-0">{vFieldnumber("cek_suhu")}</CCol>
			)}
		</CRow>

		<CRow className="mx-0">
			<CCol>{uBahasaObj.caption_beratbadan}</CCol>
			{(uDataupdateObj.fieldedit||"") !== "berat_badan" ? (
			<>
			<CCol xs="7" className="text-end fw-bolder">
				{UFunc.formatAngka(uDatamainObj.berat_badan)} KG
			</CCol>
			<CCol xs="1" className="text-center">
			<CTooltip content={uBahasaObj.word_edit+" "+uBahasaObj.caption_beratbadan}>
			<CLink onClick={()=>hdlKlikEditfield("berat_badan")}
				className="classcursorpointer classikontabel classikonedit"/>
			</CTooltip>
			</CCol>
			</>
			) : (
			<CCol xs="8" md="5" className="pe-0 text-end ps-lg-0">{vFieldnumber("berat_badan")}</CCol>
			)}
		</CRow>
		</>
		)}
		</CCardBody>
		</CCard>
		)//>
	}
	const contentTemplate = () => {
		if(uHtml500msg) return "";

		return (
		<CCard className="mb-3 classcardbg">
		<CCardHeader><b>{uBahasaObj.caption_notetemplate}</b></CCardHeader>
		<CCardBody>
		{uISDataloading ? (
			<div className="text-center py-3">
			<CSpinner color="primary" className="mx-auto"/>
			</div>
		) : (
		<>
		<CRow className="mx-0">
		{(uDataupdateObj.fieldedit||"") !== "note_template" ? (
		<>
		<CCol className="text-capitalize">
			{UFunc.renderHTML(uDatamainObj.note_template||"-")}
		</CCol>
		<CCol xs="1" className="text-center">
			<CTooltip content={uBahasaObj.word_edit+" "+uBahasaObj.caption_notetemplate}>
			<CLink onClick={()=>hdlKlikEditfield("note_template")} 
				className="classcursorpointer classikontabel classikonedit"/>
			</CTooltip>
		</CCol>
		</>
		) : (
		<CCol className="">
			<CInputGroup size="sm">
				<CFormTextarea size="sm" rows={5}
					value={UFunc.br2nl(uDataupdateObj.value||"")}
					onChange={(e)=>{uDataupdateObj.value=UFunc.nl2br(e.target.value);setEfekview(!uEfekview)}}
					onKeyDown={(e)=>hdlKDownfield(e,"note_template")}
					readOnly={uDataupdateObj.isProcessing||false}
					id="inpeditfield"/>
				{(uDataupdateObj.isProcessing||false)===false ? (
				<CInputGroupText className="bg-transparent border-0 p-0 align-items-start">
				<CButton size="sm" color="secondary"
					onClick={()=>hdlKlikProsesfield("note_template")}
					id="btneditfield">
				<CIcon icon="cilCheckAlt" className="text-white"/>
				</CButton>
				</CInputGroupText>
				) : (
				<CInputGroupText className="bg-transparent border-0 align-items-start">
				<CSpinner color="danger"/>
				</CInputGroupText>
				)}
			</CInputGroup>
			<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea}</div>
		</CCol>
		)}
		</CRow>
		</>
		)}
		</CCardBody>
		</CCard>
		)//>
	}
	const contentFoto = () => {
		if(uHtml500msg) return ""

		const vDatafotoArr	= uDatamainObj.foto_arr || [];
		const vISFotoprosess= uDatamainObj.isFotoprocess || false;
		return (
		<CCard className="mb-3 classcardbg">
		<CCardHeader className="d-flex justify-content-between">
			<b>{uBahasaObj.caption_fotoperiksa}</b>
			<CTooltip content={"--"+(uBahasaObj.caption_previewfoto||"caption_previewfoto")}>
			<CLink onClick={()=>hdlKlikFotoview()} className="classcursorpointer classikon classikonfoto"/>
			</CTooltip>
		</CCardHeader>
		<CCardBody>
		{uISDataloading ? (
			<div className="text-center py-3">
			<CSpinner color="primary" className="mx-auto"/>
			</div>
		) : (
			<div className="p-2 d-flex justify-content-start align-items-center" 
				style={{flexFlow:"row wrap"}}>
			{vDatafotoArr.map((vItems,vKeys)=>{
				const {
					id,url_image,
				} = vItems;

				return (
				<div key={vKeys} className={"classbordersquare me-2 p-1"}>
					<div className="mx-auto"
						style={{
							backgroundSize:"cover",
							backgroundColor:"transparent",
							backgroundImage:"url("+url_image+")",
							backgroundPosition:"center center",
							backgroundRepeat:"no-repeat",
							//background: "transparent url("+vItems.url_image+") center center no-repeat",
							height:65,width:65}}>
						<div 
							style={{
								cursor:"pointer",
								height:65,width:65
							}}
							className="classactionfoto"
							onClick={()=>{hdlKlikfotohapus(vKeys)}}/>
					</div>
				</div>
				)
			})}
			{(vISFotoprosess) ? (
				<div className="me-2 d-flex justify-content-center align-items-center"
					style={{height:70,width:70}}>
				<CSpinner />
				</div>
			) : (
				<div className="classbordersquare d-flex justify-content-center align-items-center" 
					onClick={(e)=>hdlKlikFototambah(e)}
					style={{cursor:"pointer",height:70,width:70}}>
				<CIcon className="classikontambah4" height={30}/>
				</div>
			)}
			<input
				type="file"
				Accept="image/png, image/jpeg, image/gif, capture=camera"
				ref={refFileImg}
				onChange={(e)=>hdlChangeFotofake(e)}
				className="d-none"
				multiple="multiple"
				id="inpfileimg"/>
			</div>
		)}
		</CCardBody>
		</CCard>
		)//>
	}
	const contentPayment = () => {//--TIDAK_DIPAKAI--/
		if(uHtml500msg) return "";

		return "";//--KARENA_SATU_NOTA_BISA_BANYAK_RM_PASIEN

		const vItempaymentArr	= uDatamainObj.payment_arr || [];
		const vSumnilai			= 0;

		return (
		<CCard className="mb-3 classcardbg">
		<CCardHeader><b>{uBahasaObj.caption_datapayment||"Data Pembayaran"}</b></CCardHeader>
		<CCardBody style={{backgroundColor:"#DCDCDC88"}}>
		{uISDataloading ? (
			<div className="text-center py-3">
			<CSpinner color="primary" className="mx-auto"/>
			</div>
		) : (
		<table className="table table-borderless" id="idtabeldata">
		<tbody>
			<tr className="align-top classfontsmaller">
			<td align="right" width="5%">&nbsp;</td>
			<td align="left" width="20%">&nbsp;</td>
			<td align="left">&nbsp;</td>
			<td align="right" width="20%">&nbsp;</td>
			</tr>
		{vItempaymentArr.map((vItems,vKeys)=>{
			const {
				id,tgl_bayar,no_nota,nilai_bayar
			} = vItems;

			return (
			<tr className="align-top classfontsmaller" key={vKeys}>
			<td align="right">{vKeys}.</td>
			<td align="left">{UFunc.TglAngka(tgl_bayar)}</td>
			<td align="left">{(no_nota||"-")}</td>
			<td align="right" className="text-success">{UFunc.formatAngka(nilai_bayar)}</td>
			</tr>
			)
		})}
		</tbody>
		<tfoot>
			<tr className="align-top fw-bolder">
			<td align="center" colSpan={3}>{uBahasaObj.word_jumlah} (Rp)</td>
			<td align="right" className="text-success">
				{UFunc.formatAngka(vSumnilai)}
			</td>
			</tr>
		</tfoot>
		</table>
		)}
		</CCardBody>
		</CCard>
		)//>
	}
	const contentTerapi = () => {
		if(uHtml500msg) return "";

		const vItemterapiArr	= uDatamainObj.terapi_arr || [];
		const vSumnilai			= uDatamainObj.total_terapi || 0;

		return (
		<CCard className="classcardbg mb-3" style={{flex:"1 0 0"}}>
		<CCardHeader><b>{uBahasaObj.caption_itemtreatment}</b></CCardHeader>
		<CCardBody className="" style={{backgroundColor:"#f0f0f088"}}>
		{uISDataloading ? (
			<div className="text-center py-5">
			<CSpinner color="primary" className="mx-auto"/>
			</div>
		) : (
		<table className="table table-borderless" id="idtabeldata">
		<thead>
			<tr className="align-top text-center classborderbottom classfontsmaller">
			<th width="5%">No</th>
			<th className="text-start">{uBahasaObj.caption_itemterapi}</th>
			<th width="15%">{uBahasaObj.word_qty}</th>
			<th width="18%">{uBahasaObj.caption_hsatuan}</th>
			<th width="18%">{uBahasaObj.word_total}</th>
			</tr>
		</thead>
		<tbody>
		{vItemterapiArr.map((vItems,vKeys)=>{
			const {
				id,terapi_caption,qty,harga_satuan,satuan
			} = vItems;

			return (
			<tr className="align-top classfontsmaller classborderbottom" key={vKeys}>
			<td align="right">{vKeys+1}.</td>
			<td align="left">{(terapi_caption||"UNDF")}</td>
			<td align="center"><b>{UFunc.formatAngka(qty)}</b> <small>{satuan}</small></td>
			<td align="right">{UFunc.formatAngka(harga_satuan)}</td>
			<td align="right" className="text-success">{UFunc.formatAngka(parseFloat(qty||"0") * parseFloat(harga_satuan||"0"))}</td>
			</tr>
			)
		})}
		</tbody>
		<tfoot>
			<tr className="align-top fw-bolder">
			<td align="center" colSpan={4}>{uBahasaObj.word_jumlah} (Rp)</td>
			<td align="right" className="text-success">
				{UFunc.formatAngka(vSumnilai)}
			</td>
			</tr>
		</tfoot>
		</table>
		)}
		</CCardBody>
		</CCard>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsDlgviewshow) return (<></>)//>

		const vFotoArr 	= uDatamainObj.foto_arr || [];
		let vFotodlgArr	= []
		vFotoArr.map(vItems=>{
			const vTmpObj = {
				original:vItems.url_image,
				thumbnail:vItems.url_image,
				thumbnailHeight:85,
				thumbnailWidth:85,
			}
			vFotodlgArr.push(vTmpObj);
		})

		return (
		<ImageGallery 
			items={vFotodlgArr}/>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg()
		setDatamainObj({})
		setDataloading(true); setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Pasienrmdetil - apiLoaddata) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDataloading(false); setLoading(false)
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uRekammedisID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_dataform";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setDataloading(false); setLoading(false)
			if(output_string.tampil) {
				setDatamainObj(JSON.parse(output_string.datamain||"{}"));
			} else if(output_string.info) {
				if(output_string.dataexpired) {
					showToast(output_string.info);
				} else {
					setHtml500msg(output_string.info);
				}
			} else if(output_string.errors) {
				console.log("(Pasienrmdetil - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				setHtml500msg("<span class='text-danger'>"+vMsg+"</span>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			setDataloading(false); setLoading(false)
			console.log("(Pasienrmdetil - apiLoaddata) catch-error: "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesedit = (_NAMAFIELD) => {
		_NAMAFIELD	= (_NAMAFIELD||"").trim();
		
		let vValue	= uDataupdateObj.value;
		if(_NAMAFIELD === "cek_suhu" || _NAMAFIELD === "berat_badan") {
			if(isMobile) {
			 	vValue 	= parseFloat(vValue.replace(",","."))||0;
			 	uDataupdateObj.value = vValue;
			}
		}
		uDataupdateObj.isProcessing = true;
		setEfekview(!uEfekview);

		/*//---TEST_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setDataupdateObj({});
			setEfekview2(!uEfekview2);
		},2500); return;
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uRekammedisID,
			send_dataedit: JSON.stringify(uDataupdateObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_editdata";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDataupdateObj.isProcessing = false;
			if(output_string.sukses) {
				uDatamainObj[uDataupdateObj.fieldedit] = vValue;

				setDataupdateObj({});
				setEfekview2(!uEfekview2)
			} else {
				setEfekview2(!uEfekview2)

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Pasienrmdetil - apiProsesedit) output_string.errors : "+output_string.errors);
					const vMsg	= (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true);prosesExpired(); return;
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uDataupdateObj.isProcessing = false;
			setEfekview2(!uEfekview2)

			console.log("(Pasienrmdetil - apiProsesedit) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesfoto = (_BASE64ARR) => {
		_BASE64ARR		= _BASE64ARR || [];
		const vFotoArr	= uDatamainObj.foto_arr || []
		uDatamainObj.isFotoprocess = true
		setEfekview(!uEfekview)

		/*//---TEST_FRONTEND--/
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uRekammedisID,
			send_base64str: JSON.stringify(_BASE64ARR),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_fototambah";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDatamainObj.isFotoprocess = false
			if(output_string.sukses) {
				const vNewoutputArr	= JSON.parse(output_string.dataoutputs || "[]");
				//-->FORMAT: { id:x, url_image: urls}

				uDatamainObj.foto_arr	= [...vFotoArr,...vNewoutputArr];
				setEfekview2(!uEfekview2)
			} else {
				setEfekview2(!uEfekview2)

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Pasienrmdetil - apiProsesfoto) output_string.errors : "+output_string.errors);
					const vMsg	= (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true);prosesExpired(); return;
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uDatamainObj.isFotoprocess = false
			setEfekview2(!uEfekview2)

			console.log("(Pasienrmdetil - apiProsesfoto) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesfotohapus = (_IDX) => {
		_IDX 		= parseInt(_IDX); if(_IDX < 0) return 

		uDatamainObj.isFotoprocess = true;
		setEfekview(!uEfekview);
		let vFotoArr		= uDatamainObj.foto_arr || []
		const vFotoID		= vFotoArr[_IDX].id

		/*//---TEST_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") vFotoArr : "+JSON.stringify(vFotoArr));
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout)
			vFotoArr.splice(_IDX,1)

			setFotoprocess(false)
			uDatamainObj.foto_arr = vFotoArr
			setViewupdate(!uViewupdate)
		},2000); return
		//---END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_fotoid: vFotoID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_fotohapus";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			uDatamainObj.isFotoprocess = false;
			if(output_string.sukses) {
				vFotoArr.splice(_IDX,1)

				uDatamainObj.foto_arr = vFotoArr
				setEfekview2(!uEfekview2);
			} else {
				setEfekview2(!uEfekview2);

				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {

					console.log("(Pasienrmdetil - apiProsesfotohapus) output_string.errors : "+output_string.errors);
					const vMsg	= (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true);prosesExpired(); return;
				}
			}

			if(vTimeDiff > 500) setSessionaktif(true); else {
				let vTimeout = setTimeout(()=>{clearTimeout(vTimeout);setSessionaktif(true);},500);
			}
		})
		.catch((error) =>{
			uDatamainObj.isFotoprocess = false;
			setEfekview2(!uEfekview2);

			console.log("(Pasienrmdetil - apiProsesfotohapus) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
    	setLoading(false);
    	hdlToggleDlgview();

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoaddata();

    	return () => {
    		setDatamainObj({}); setDataupdateObj({});
    		setHtml500msg();
    	}
	},[])
	React.useEffect(()=>{
		const vItemterapiArr = uDatamainObj.terapi_arr||[];
		if(vItemterapiArr.length <= 0) {
			uDatamainObj.total_terapi = 0;
			return
		}
		const vTotals	= vItemterapiArr.reduce((SUM,vItems)=>
			SUM + (parseFloat(vItems.harga_satuan) * parseFloat(vItems.qty))
		,0)
		uDatamainObj.total_terapi = vTotals;
		setEfekview(!uEfekview);
		//console.log("(Pasienrmdetil - [uDatamainObj]) vTotals = > "+vTotals)
	},[uDatamainObj,uDatamainObj.terapi_arr])
	React.useEffect(()=>{
		uDatamainObj.layanan_color	= undefined;
		uDatamainObj.layanan_caption= undefined;
		if(uDatamainObj.layanan_id === undefined) { return; }

		const vArrIdx = UFunc.getObjectIndeks(uLayananArr,uDatamainObj.layanan_id);
		//console.log("(Pasienrmdetil - [uDatamainObj]) vArrIdx => "+(vArrIdx))
		if(vArrIdx < 0) return;
		uDatamainObj.layanan_color	= uLayananArr[vArrIdx].color_layanan;
		uDatamainObj.layanan_caption= uLayananArr[vArrIdx].caption_layanan;//uBahasaObj["layanan_"+(uLayananArr[vArrIdx].value_layanan||"").toLowerCase()];
		setEfekview(!uEfekview);
	},[uDatamainObj,uDatamainObj.layanan_id])
	//---END EFFECT--/

	//console.log("(Pasienrmdetil) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="p-0 me-2" onClick={()=>uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="d-none d-md-inline-block">
					{((uBahasaObj.word_detil||"Detil")+" "+
					(uBahasaObj.word_rm||"RM"))}
					<span className="me-1">:</span>
				</small>
				<b>{isMobile ? UFunc.Usercaptionfilter((uDatamainObj.pasien_caption || "UNDF"),3) : (uDatamainObj.pasien_caption || "UNDF")}</b>
			</CHeaderBrand>

			<CHeaderNav className="align-items-center">
				<div className="d-none d-md-block">
				<CTooltip content={uBahasaObj.caption_resetview}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer me-1"/>
				</CTooltip>
				<span className="me-1">&middot;</span>
				</div>
				<CLink onClick={()=>hdlKlikReset()} className="d-md-none classikon classikonreset classcursorpointer me-1"/>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer className="m-0 p-2 px-lg-4"
			style={{backgroundColor:"#"+(uDatamainObj.layanan_color||"ffffff")+"11"}}
			fluid>
			{uHtml500msg ? (<b>Sorry, There is Problem(s)..</b>) : (
			<>
			{uISDataloading ? (
				<span className="text-primary classblinking">Loading...</span>
			): (
				<>
				<div>
					<span className="mx-1">&middot;</span>
					{uBahasaObj.word_treatment||"Treatment"}: <b>{UFunc.TglAngka(uDatamainObj.tgl_kunjungan)}</b>
					<span className="mx-1">&middot;</span>
				</div>
				<div>
					<b style={{color:"#"+(uDatamainObj.layanan_color||"000000")}}>{uDatamainObj.layanan_caption||""}</b>
				</div>
				</>
			)}
			</>
			)}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-md-3">
		<CContainer fluid className="px-0">
			{uHtml500msg ? (
			<div className="mx-md-3 mx-lg-5 px-lg-5">
				<MyPage500 content={uHtml500msg}/>
			</div>
			) : (
			<CRow className="">
			<CCol md="6" className="" style={{}}>
				{contentAnamnesa()}
				{contentTemplate()}
				{contentFoto()}
			</CCol>
			<CCol className="d-flex flex-column">
				{contentTerapi()}
				{contentPayment()}
			</CCol>
			</CRow>
			)}
		</CContainer>
		</div>
		</div>

		<MyDialogview
			options={{centered:false,bgColor:"black",size:"xl"}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>
		</>
	)//>
}	

export default Pasienrmdetil