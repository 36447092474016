import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCardHeader,
	CCard,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CFormInput,
	CFormSelect,
	CBadge,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
	CSpinner,
} from '@coreui/react';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import { 
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

const pjson     = require('../../package.json');
const Barang = (props) => {
	const uComponentname 	= Barang.name;
	const {
		setLoading, showToast, prosesExpired, setSessionaktif
	} = props; 

	let uFuncname;//-->hanya untuk API
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uHTMLstyleTabel=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uSystemsettingObj= useSelector(state => state.gSystemsetting);

	//---VAR_GLOBALS--/
	const uSettingObj		= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus	= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData							= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState();

	const uKeywordObj						= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJenisBarangArr,setJenisBarangArr]= React.useState([{
		value:"0",caption:"--"+(uBahasaObj.caption_dataempty||"caption_dataempty").toUpperCase()+"--"}
	]);
	const [uJenisobatArr,setJenisobatArr]= React.useState([{
		value:"0",caption:"--"+(uBahasaObj.caption_dataempty||"caption_dataempty").toUpperCase()+"--"}
	]);

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;
	const uHandelCetak 		= uHeaderActionObj.isHeaderCetak || false;

	const [uISProcessviewhpp,setProcessviewhpp]		= React.useState(false);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uDataCetakObj,setDataCetakObj]	= React.useState([]);
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpBarangkode,setInpBarangkode]	= React.useState("");
	const [uInpBarangnama,setInpBarangnama] = React.useState("");
	const [uInpSatuan,setInpSatuan]			= React.useState("PCS");
	const [uInpHargajual,setInpHargajual]	= React.useState("");
	const [uInpJenisID,setInpJenisID]		= React.useState("1");
	const [uInpJenisObatID,setInpJenisObatID]= React.useState("0");

	const [ElBtnSimpan,setElBtnSimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	//---END VAR_GLOBALS--/

	//console.log("("+uComponentname+") uHeaderActionObj.isHeaderView: "+uHeaderActionObj.isHeaderView);
	//console.log("("+uComponentname+") uHandelView: "+uHandelView);

	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	//---HANDLE--/
	const hdlToggleForm=()=>{ setShowForm(false); }
	const hdlKlikSimpan=()=>{
		//--VALIDASI_SIMPAN--/
		/*if(uInpBarangkode.trim() === "") {
			showToast(uBahasaObj.word_username+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			document.getElementById("inpbarangkode").focus();
			return;
		}//-*/
		if(uInpBarangnama.trim() === "") {
			showToast((uBahasaObj.caption_namabarang||"caption_namabarang")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			document.getElementById("inpbarangnama").focus();
			return;
		}
		if(uInpSatuan === "") {
			showToast((uBahasaObj.word_satuan||"word_satuan")+" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+".."); 
			document.getElementById("inpsatuan").focus();
			return;
		}
		if(uInpJenisID.toString() === "0") {
			showToast((uBahasaObj.word_jenis||"word_jenis")+" "+(uBahasaObj.caption_mustchoosed||"caption_mustchoosed")+".."); 
			document.getElementById("inpjenisid").focus();
			return;
		}
		//---END VALIDASI--/
		//--END VALIDASI_SIMPAN--/

		apiProses();
	}
	const hdlKlikEdit=async(_IDTABEL)=>{

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setInpBarangkode(uDatamainArr[vObjdx].kode);
		setInpBarangnama(uDatamainArr[vObjdx].nama);
		setInpJenisID(uDatamainArr[vObjdx].jenis_id);
		setInpJenisObatID(uDatamainArr[vObjdx].jenis_obat_id);
		setInpSatuan(uDatamainArr[vObjdx].satuan);
		setInpHargajual(uDatamainArr[vObjdx].h_jual);
		setDialogHeader((uBahasaObj.caption_editdata||"caption_editdata")+": "+(uDatamainArr[vObjdx].nama || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": <B>"+uDatamainArr[vObjdx].nama.toUpperCase()+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiHapus(_IDTABEL);
	}
	const hdlToggleviewhpp = () => {
		apiProsesviewhpp();
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initCetakClosing = () => {
		var vContent 	= contentCetak();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();

		setDataCetakObj([]);
	}
	const initFormAwal = () => {
		setTabelIDPilih(0);

		setInpBarangkode("");
		setInpBarangnama("");
		setInpSatuan("PCS");
		setInpHargajual("");
		setInpJenisID("1");
	}
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_barang || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vISviewhpp	= (uSystemsettingObj.view_hpp_barang || "YA") === "YA";
		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width="5%" className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.caption_kodebarang||"caption_kodebarang"}</CCol>
			<CCol md="4" lg="5" className="text-start">{uBahasaObj.caption_namabarang||"caption_namabarang"}</CCol>
			<CCol className="d-none">{uBahasaObj.word_sat||"word_sat"}</CCol>
			<CCol md={vISviewhpp ? "2" : "1"} className="d-flex justify-content-center align-items-center">
				{vISviewhpp && (
				<div className="me-2">
				{uBahasaObj.caption_hppsatuan||"caption_hppsatuan"}
				<div>
				(Rp)
				</div>
				</div>
				)}

				{uISProcessviewhpp ? (
					<CSpinner color="primary" size="sm"/>
				) : (
				<CTooltip content="Toggle Show/Hide HPP">
				<CLink className="classcursorpointer" onClick={()=>hdlToggleviewhpp()}>
					<CIcon icon={vISviewhpp ? "cilFlip" : "cilFlipToBack"} 
						className="classikoncoreui fw-bolder" 
						style={{backgroundColor:(!vISviewhpp ? "#FC6F3A55" : "")}} 
						height={20}/>
				</CLink>
				</CTooltip>
				)}
			</CCol>
			<CCol md={vISviewhpp ? "2" : "3"} className="">{uBahasaObj.caption_hargajual||"caption_hargajual"}<div>(Rp)</div></CCol>
			<CCol md="2" lg="1" className="">&middot;</CCol>
			</CRow>
		</th>
		</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,kode,nama,jenis_barang,jenis_obat,h_jual,satuan,hpp_sat,
				jenis_id
			} = vItems;

			vNumber++;
			const vCaptionupper	= (nama||"").toUpperCase();

			const vBarangnama	= vKeyword==="" ? nama 
				: (nama||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vBarangkode= vKeyword==="" ? (kode||"-") 
				: (kode||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vBarangjenis= vKeyword==="" ? jenis_barang 
				: (jenis_barang||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vObatjenis= vKeyword==="" ? (jenis_obat||"") 
				: (jenis_obat||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vBarangsatuan= vKeyword==="" ? satuan 
				: (satuan||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vHargaJual	= UFunc.formatAngka(h_jual);
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>
				<td className="px-1 ps-0">
				<CRow className="mx-0">
					<CCol xs="7" md="2" className="px-1 text-md-start d-none d-md-block text-uppercase">
						<small>{UFunc.renderHTML(vBarangkode.toUpperCase())}</small>
					</CCol>

					<CCol xs="12" md="4" lg="5" className="px-1 text-start d-flex justify-content-between">
						<div>
						<span className="text-info">{UFunc.renderHTML(vBarangnama)}</span>
						<span className="mx-1 d-none d-md-inline">&middot;</span>
						<small className="d-none d-md-inline">
						({UFunc.renderHTML(vBarangjenis)}
							{jenis_id.toString()==="2" && (
								<span className="d-none"> &middot; {UFunc.renderHTML(vObatjenis)}</span>
							)}
						)
						</small>
						</div>

						<CDropdown className="d-md-none" autoclose={true}>
						<CDropdownToggle color="transparent" className="p-0 m-0" 
							caret={false}>
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem 
								onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikontabel classikonedit me-2" height={25}/>
								<span>{uBahasaObj.caption_editdata}</span>
							</CDropdownItem>
							
							{uIsAllowedhapus && (
							<CDropdownItem 
								onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikontabel classikonhapus me-2" height={25}/>
								<span>{uBahasaObj.caption_hapusdata}</span>
							</CDropdownItem>
							)}
						</CDropdownMenu>
						</CDropdown>
					</CCol>

					<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

					<CCol xs="5" className="px-1 text-start d-md-none">
						<>{uBahasaObj.caption_kodebarang||"caption_kodebarang"}</>
					</CCol>
					<CCol xs="7" md="2" className="px-1 d-md-none">
						<span className="text-uppercase">{UFunc.renderHTML(vBarangkode.toUpperCase())}</span>
					</CCol>

					<CCol xs="5" className="px-1 text-start d-md-none">
						<>{uBahasaObj.word_jenis||"word_jenis"}</>
					</CCol>
					<CCol xs="7" className="px-1 d-md-none text-uppercase">
						{UFunc.renderHTML(vBarangjenis)}
						{jenis_id.toString()==="2" && (
							<span className="d-none"> &middot; {UFunc.renderHTML(vObatjenis)}</span>
						)}
					</CCol>

					<CCol className="px-1 text-start d-none">
						<>{uBahasaObj.word_sat||"word_sat"}</>
					</CCol>
					<CCol className="d-none px-1 px-md-0 text-md-center text-uppercase classfontsmaller">
						<small className="d-none d-md-block">{UFunc.renderHTML(vBarangsatuan)}</small>
						<span className="d-md-none">{UFunc.renderHTML(vBarangsatuan)}</span>
					</CCol>

					<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

					<CCol xs="5" className={"px-1 text-start d-md-none"+(vISviewhpp?"":" d-none")}>
						{uBahasaObj.caption_hppsatuan||"caption_hppsatuan"}
					</CCol>
					<CCol xs="7" md={vISviewhpp ? "2" : "1"} className={"px-1 text-danger"+(vISviewhpp?"":" text-md-center d-none d-md-block")}>
						{vISviewhpp ? (
						<>
						<small className="d-none d-md-block">{UFunc.formatAngka(hpp_sat)}</small> 
						<div className="d-md-none">Rp{UFunc.formatAngka(hpp_sat)}</div>
						</>
						) : (<>&middot;</>)}
					</CCol>

					<CCol xs="5" className="px-1 text-start d-md-none">
						{uBahasaObj.caption_hargajual||"caption_hargajual"}
					</CCol>
					<CCol xs="7" md={vISviewhpp ? "2" : "3"} className="px-1 fw-bolder text-primary">
						<span className="d-md-none">Rp</span>{vHargaJual}
					</CCol>

					<CCol md="2" lg="1" className="px-0 d-none d-md-block text-md-center">
						<CTooltip content={"--"+(uBahasaObj.caption_editdata || "caption_editdata")+": "+vCaptionupper}>
						<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer">
						</CLink>
						</CTooltip>
						{uIsAllowedhapus && (
						<>
							<span className="d-md-none mx-1">&middot;</span>
							<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionupper}>
							<CLink 
								className={"classikontabel classikonhapus classcursorpointer"}
								onClick={()=>hdlKlikHapus(id)} />
							</CTooltip>
						</>
						)}
					</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentForm = () => {
		if(uHTMLError500) return "";

		return (
		<CForm className="mx-lg-5">
		<CRow className="my-2">
			<CCol md="4" className="text-start text-md-end">
				{uBahasaObj.caption_namabarang||"caption_namabarang"}
			</CCol>
			<CCol md="8" className="text-end">
				<CFormInput maxLength={50} size="sm"
					className="text-capitalize"
					value={uInpBarangnama}
					onChange={(e) => setInpBarangnama(e.target.value)}
					id="inpbarangnama"/>
			</CCol>
		</CRow>

		<div className="my-3 d-md-none classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_kodebarang||"caption_kodebarang"}
			</CCol>

			<CCol xs="8" lg="4" className="text-end">
			<CFormInput maxLength={15} size="sm"
				className="form-control text-uppercase"
				value={uInpBarangkode}
				onChange={(e) => setInpBarangkode(e.target.value)}
				id="inpbarangkode"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.word_jenis||"word_jenis"}
			</CCol>
			<CCol xs="8" lg="4" className="text-end">
				<CFormSelect name="inpjenisid" id="inpjenisid"
					value={uInpJenisID}
					onChange={(e)=>setInpJenisID(e.target.value)} 
					size="sm">
				{uJenisBarangArr.map((vItems,vKeys)=>{
					return(
					<option value={vItems.value} key={vKeys}>{vItems.caption}</option>
					)
				})}
				</CFormSelect>
			</CCol>
		</CRow>

		{(uInpJenisID||"0").toString()==="2" && (
		<CRow className="my-2 d-none">
			<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_jenisobat||"caption_jenisobat"}
			</CCol>
			<CCol xs="8" lg="4" className="text-end">
				<CFormSelect  
					value={uInpJenisObatID}
					onChange={(e)=>setInpJenisObatID(e.target.value)} 
					size="sm"
					id="inpjenisid">
				{uJenisobatArr.map((vItems,vKeys)=>{
					return(
					<option className="text-uppercase" value={vItems.value} key={vKeys}>{vItems.caption}</option>
					)
				})}
				</CFormSelect>
			</CCol>
		</CRow>
		)}

		{(!uIsAllowedhapus && uTabelIDPilih > 0) && (
			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_hargajual||"caption_hargajual"}
			</CCol>
			<CCol xs="8" lg="4" className="text-end fw-bolder">
				Rp {UFunc.formatAngka(uInpHargajual)}
			</CCol>
			</CRow>
		)}
		{(uIsAllowedhapus || uTabelIDPilih <= 0) && (
			<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.caption_hargajual||"caption_hargajual"}
			</CCol>
			<CCol xs="8" lg="4" className="text-end">
				<NumberFormat 
					value={uInpHargajual}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInpHargajual(values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e) => setInpHargajual(e.target.value)}
							onFocus={(e)=>e.target.select()}
						id="inphargajual"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>
		)}

		<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">
				{uBahasaObj.word_satuan||"word_satuan"}
			</CCol>
			<CCol xs="4" className="text-end">
				<CFormInput maxLength={15} size="sm"
					className="form-control text-uppercase"
					value={uInpSatuan}
					onChange={(e) => setInpSatuan(e.target.value)}
					onKeyDown={(e) => UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpsatuan"/>
			</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentCetak=()=>{
		const vKeyword = uKeywordObj.kwd_barang || "";
		var vHTMLs = uHTMLstyleTabel;

		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="6">
			<div>`+uBahasaObj["menus_"+uActiveroute.toLowerCase()]+`</div>
			`;
		vHTMLs += vKeyword!="" 
			? "<div style='font-size:smaller;font-weight:normal;'>"+(uBahasaObj.word_keyword||"word_keyword")+": <U>"+vKeyword+"</U></div>" : "";

		vHTMLs += `
			</td>
			</tr>
			<tr>
				<th width="7%">No</th>
				<th width="15%" align="left">`+uBahasaObj.caption_kodebarang+`</th>
				<th align="left">`+uBahasaObj.caption_namabarang+`</th>
				<th width="7%">`+(uBahasaObj.word_sat||"word_sat")+`</th>
				<th width="11%">`+(uBahasaObj.word_hpp||"word_hpp")+` (Rp)</th>
				<th width="18%">`+uBahasaObj.caption_hargajual+` (Rp)</th>
			</tr>
			</thead>
			<tbody>`;

		uDataCetakObj.forEach((vItems,vKey)=>{
			const {
				id,kode,nama,jenis_barang,jenis_id,jenis_obat,satuan,h_jual,hpp_sat
			} = vItems;

			vHTMLs 	+= `
			<tr key={vKey} valign="top">
				<td align="right" style="font-size:smaller">`+(vKey+1)+`.</td>
				<td align="left" style="font-size:smaller">`+(kode||"-")+`</td>
				<td align="left">`+nama+
					` <span style='font-size:smaller;'>[`+
					jenis_barang+
					`]</span></td>
				<td align="center" style="font-size:smaller">`+satuan+`</td>
				<td align="right" style="font-weight:smaller;">`+UFunc.formatAngka(hpp_sat)+`</td>
				<td align="right" style="font-weight:bolder;">`+UFunc.formatAngka(h_jual)+`</td>
			</tr>
			`;
		});
		vHTMLs 	+= `
			</tbody>
		`
		vHTMLs 	+= `</table>`;

		return vHTMLs;
	}
	//---END Init_LOAD--/

	//---API_FUNC--/
	const apiLoaddata = () => {
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_barang || "";

		/*//--TESTING_FRONTEND--/
		return;
		//--END TESTING_FRONTEND--*/

		setJmlData(0);
		setDatamainArr([]);
		setDataCetakObj([]);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setPageActive(1);

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barang/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setJenisBarangArr(JSON.parse(output_string.jenislist || "[]"));
				setJenisobatArr(JSON.parse(output_string.jenisobatlist || "[]"));

				const vDatamainArr = JSON.parse(output_string.dataobject || "[]");
				setDatamainArr(vDatamainArr);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProses=()=>{
		uFuncname = apiProses.name;

		/*//--TESTING_FRONTEND--//
		//---CONCAT_TO_CURRENT_DATA_OBJ--/
		return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_barangkode : (uInpBarangkode||"").trim(),
			send_barangnama : uInpBarangnama.trim(),
			send_satuan : uInpSatuan.trim(),
			send_hargajual : uInpHargajual,
			send_jenisid : uInpJenisID,
			send_jenisobatid : uInpJenisID.toString()!=="2" ? "0" : uInpJenisObatID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barang/pr_simpan";

		setLoading(true);
		ElBtnSimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			ElBtnSimpan.disabled = false;

			if(output_string.sukses) {
				//---CONCAT_TO_CURRENT_DATA_OBJ--/
				let vDataObjNew;
				if(uTabelIDPilih === 0) {
					const vTmpObject	= {
						id: output_string.tabelid || "255",
						kode : (uInpBarangkode||"").trim(),
						nama : UFunc.capitalize(uInpBarangnama.trim()),
						jenis_barang: uJenisBarangArr[uJenisBarangArr.findIndex(x => x.value.toString() === uInpJenisID.toString())].caption,
						jenis_id : uInpJenisID,
						jenis_obat: uInpJenisID!=="2" ? "" : uJenisobatArr[uJenisobatArr.findIndex(x => x.value.toString() === uInpJenisObatID.toString())||0].caption,
						jenis_obat_id : uInpJenisObatID,
						satuan: uInpSatuan,				
						hpp_sat : 0,				
						h_jual : uInpHargajual,				
					}
					vDataObjNew = [vTmpObject].concat(uDatamainArr);
				} else {
					vDataObjNew = uDatamainArr;
					const vObjdx				= UFunc.getObjectIndeks(vDataObjNew,uTabelIDPilih);
					vDataObjNew[vObjdx].kode 	= (uInpBarangkode||"").trim();
					vDataObjNew[vObjdx].nama 	= uInpBarangnama.trim();
					vDataObjNew[vObjdx].jenis_barang = uJenisBarangArr[uJenisBarangArr.findIndex(x => x.value === uInpJenisID.toString())].caption;
					vDataObjNew[vObjdx].jenis_id= uInpJenisID;
					vDataObjNew[vObjdx].jenis_obat = uInpJenisID!=="2" ? "" : uJenisobatArr[uJenisobatArr.findIndex(x => x.value === uInpJenisObatID.toString())].caption;
					vDataObjNew[vObjdx].jenis_obat_id= uInpJenisObatID;
					vDataObjNew[vObjdx].satuan 	= uInpSatuan.trim();
					vDataObjNew[vObjdx].h_jual 	= uInpHargajual;
				}

				setDatamainArr(vDataObjNew);
				initFormAwal();
				//---END CONCAT_TO_CURRENT_DATA_OBJ--/

				if(uTabelIDPilih > 0) {
					setEfekview(!uEfekview);
					hdlToggleForm();
				}  else {
					setTabelRowSelect(-1);
					setJmlData((parseInt(vDataObjNew.length)||0));
					showToast((uBahasaObj.pesan_sukses || "pesan_sukses"),"SUKSES");
					setPageActive(1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				ElBtnSimpan.disabled = true;
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			setLoading(false);
			ElBtnSimpan.disabled = false;
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiHapus=(_IDTABEL)=>{
		uFuncname 	= apiHapus.name;
		_IDTABEL = parseInt(_IDTABEL) || 0;
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		return;
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barang/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadCetak=()=>{
		uFuncname = apiLoadCetak.name;

		const vKeyword = uKeywordObj.kwd_barang || "";

		if(vKeyword.length > 2) {
			setDataCetakObj(uDatamainArr);
			return;
		}

		setDataCetakObj([]);
		const vDATAS	= JSON.stringify({
			send_initcetak : "YES",
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barang/ld_data";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataCetakObj(vTmpObj);
				}//-*/
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesviewhpp = () => {
		const vInitviewhpp	= (uSystemsettingObj.view_hpp_barang || "YA")==="YA" ? "TIDAK" : "YA";
		setProcessviewhpp(true);

		/*//--TESTING_FRONTEND--//
		const vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setProcessviewhpp(false);

			uSystemsettingObj.view_hpp_barang = vInitviewhpp;
			uDispatch({type: "set", gSystemsetting: uSystemsettingObj});

			setEfekview(!uEfekview);
		},3000); return;
		//--END TESTING_FRONTEND--//*/

		const vDATAS	= JSON.stringify({
			send_initviewhpp: vInitviewhpp,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barang/pr_togglehpp";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setProcessviewhpp(false);

			if(output_string.sukses) {
				uSystemsettingObj.view_hpp_barang = vInitviewhpp;
				uDispatch({type: "set", gSystemsetting: uSystemsettingObj});
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-apiProsesviewhpp) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setProcessviewhpp(false);
			console.log("("+uComponentname+"-apiProsesviewhpp) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

		return ()=>{
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false);
			setDatamainArr([]); setDataCetakObj([]); setJenisBarangArr([]);
		}
	},[]);
	React.useEffect(()=>{
		if(!uHandelView) return;
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(isShowForm) return;

		setDialogHeader((uBahasaObj.word_tambah||"word_tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah]);
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length)
	},[uDatamainArr,uDatamainArr.length]);
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return;}

		//console.log("("+uComponentname+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnSimpan(document.getElementById("btnDialogSimpan"));

			const vElFocus = document.getElementById("inpbarangnama");
			vElFocus && vElFocus.focus();
		},200);
	},[isShowForm]);
	React.useEffect(()=>{
		if(!uHandelCetak) return;
		uHeaderActionObj.isHeaderCetak = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoadCetak();
	},[uHandelCetak]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);
		setPageActive(uPageActive<=0 ? 1 : uPageActive);
	},[uJmlData,uElJmldata]);
	React.useEffect(()=>{
		if(uInpJenisID.toString()!=="2") return;

		if((parseInt(uInpJenisObatID)||0)<=0) setInpJenisObatID("1");
	},[uInpJenisID]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		const vJmlCetak = parseInt(uDataCetakObj.length)||0;
		if(vJmlCetak <= 0) return;

		initCetakClosing();
	},[uDataCetakObj]);
	React.useEffect(()=>{
		const vKeyword = uKeywordObj.kwd_barang || "";
		if (vKeyword.length >= 3) {
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
			return;
		}
		uElJmldata && (uElJmldata.innerHTML = "&#8734");
	},[uJmlData,uElJmldata]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>);

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<small className="text-primary fw-bolder">
				<span className="d-md-none">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_barang || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
			</small>
			<div className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">
			{contentMain()}
			</CCardBody>
			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpan()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={hdlToggleForm}
			renderContent={contentForm()}/>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)
}
	
export default Barang;
